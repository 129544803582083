import { createAction, props } from '@ngrx/store';

import { SelectOptions } from '@app/shared/models/multiselec-options.model';

export enum DictionariesActionTypes {
    GET_DICTIONARIES_ACTION = '[Dictionaries] Get dictionaries action',
    GET_USER_ROLES_LIST_SUCCESS_ACTION = '[Dictionaries - Users] Get user roles success action',
    GET_PAYMENT_TYPES_SUCCESS_ACTION = '[Dictionaries] Get payment types success action',
    GET_PLANNING_TYPES_SUCCESS_ACTION = '[Dictionaries] Get planning types success action',
    GET_PAYMENT_STATUS_SUCCESS_ACTION = '[Dictionaries] Get payment status success action',
    GET_RESIGNED_STATUS_SUCCESS_ACTION = '[Dictionaries] Get resigned status success action',
}

const getDictionariesAction = createAction(DictionariesActionTypes.GET_DICTIONARIES_ACTION);
const getUserRolesSuccessAction = createAction(DictionariesActionTypes.GET_USER_ROLES_LIST_SUCCESS_ACTION, props<{ payload: SelectOptions[] }>());
const getPaymentTypesSuccessAction = createAction(DictionariesActionTypes.GET_PAYMENT_TYPES_SUCCESS_ACTION, props<{ payload: SelectOptions[] }>());
const getPlanningTypesSuccessAction = createAction(DictionariesActionTypes.GET_PLANNING_TYPES_SUCCESS_ACTION, props<{ payload: SelectOptions[] }>());
const getPaymentStatusSuccessAction = createAction(DictionariesActionTypes.GET_PAYMENT_STATUS_SUCCESS_ACTION, props<{ payload: SelectOptions[] }>());
const getResignedStatusSuccessAction = createAction(DictionariesActionTypes.GET_RESIGNED_STATUS_SUCCESS_ACTION, props<{ payload: SelectOptions[] }>());

export const fromDictionariesActions = {
    getDictionariesAction,
    getUserRolesSuccessAction,
    getPaymentTypesSuccessAction,
    getPlanningTypesSuccessAction,
    getPaymentStatusSuccessAction,
    getResignedStatusSuccessAction,
};
