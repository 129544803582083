import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DictionariesState } from '../reducers/dictionaries.reducer';
import { DICTIONARIES_FEATURE_KEY } from '../keys';

const selectDictionariesState = createFeatureSelector<DictionariesState>(DICTIONARIES_FEATURE_KEY);

export const selectUserRoles = createSelector(selectDictionariesState, (state) => state.userRoles);
export const selectPlanningTypes = createSelector(selectDictionariesState, (state) => state.planningTypes);
export const selectPaymentStatus = createSelector(selectDictionariesState, (state) => state.paymentStatus);
export const selectPaymentTypes = createSelector(selectDictionariesState, (state) => state.paymentTypes);
export const selectResignedStatuses = createSelector(selectDictionariesState, (state) => state.resignedStatus);
