import { Injectable, inject } from '@angular/core';
import { SelectOptions } from '@app/shared/models/multiselec-options.model';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { filter, map } from 'rxjs';

import { fromDictionariesActions } from '../actions/dictionaries.action';
import { selectUserRoles, selectPlanningTypes, selectPaymentStatus, selectPaymentTypes, selectResignedStatuses } from '../selectors/dictionaries.selectors';

@Injectable()
export class DictionariesFacade {
    private store = inject(Store);
    private translateService = inject(TranslateService);

    userRoles$ = this.store.select(selectUserRoles).pipe(filter((data): data is SelectOptions[] => data != null));

    userRolesSelectOptions$ = this.userRoles$.pipe(
        map((roles) => roles.map((role) => ({ label: <string>this.translateService.instant(role.label), value: role.value }))),
    );

    planningTypes$ = this.store.select(selectPlanningTypes).pipe(
        filter((data): data is SelectOptions[] => data != null),
        map((data) => this.buildTranslatedSelectOptions(data)),
    );

    paymentStatus$ = this.store.select(selectPaymentStatus).pipe(
        filter((data): data is SelectOptions[] => data != null),
        map((data) => this.buildTranslatedSelectOptions(data)),
    );

    paymentTypes$ = this.store.select(selectPaymentTypes).pipe(
        filter((data): data is SelectOptions[] => data != null),
        map((data) => this.buildTranslatedSelectOptions(data)),
    );

    transitTypes$ = this.store.select(selectPlanningTypes).pipe(
        filter((data): data is SelectOptions[] => data != null),
        map((data) => this.buildTranslatedSelectOptions(data)),
    );

    resignedStatuses$ = this.store.select(selectResignedStatuses).pipe(
        filter((data): data is SelectOptions[] => data != null),
        map((data) => this.buildTranslatedSelectOptions(data)),
    );

    getDictionaries(): void {
        this.store.dispatch(fromDictionariesActions.getDictionariesAction());
    }

    private buildTranslatedSelectOptions(data: SelectOptions[]) {
        return data.map((d) => {
            return <SelectOptions>{
                label: this.translateService.instant(d.label) as string,
                value: d.value,
            };
        });
    }
}
